'use client'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'

import type { TaxpayerBannerModel } from 'types/models/taxpayer-banner'

import TaxpayerBanner from './TaxpayerBanner'

import { TaxpayerBannerRenderLocation } from '../../constants/taxpayer'

type Props = {
  banner: TaxpayerBannerModel
}

const TaxpayerBannerInHome = ({ banner }: Props) => {
  const isTaxpayersSpecialVerificationBanner = banner.isSpecialVerification
  const isTaxpayerSpecialVerificationBannerFsEnabled = useFeatureSwitch(
    'web_special_verification_taxpayers_banners',
  )

  const shouldDisplayBanner =
    !isTaxpayersSpecialVerificationBanner ||
    (isTaxpayersSpecialVerificationBanner && isTaxpayerSpecialVerificationBannerFsEnabled)

  if (!shouldDisplayBanner) return null

  return (
    <TaxpayerBanner
      banner={banner}
      isBannerInFeed
      renderLocation={TaxpayerBannerRenderLocation.Feed}
    />
  )
}

export default TaxpayerBannerInHome
