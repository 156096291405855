'use client'

import { Card, Cell, Image, Text } from '@vinted/web-ui'
import classNames from 'classnames'
import { InView } from 'react-intersection-observer'

import { LayoutElementModel } from 'types/models/homepage-layouts'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'

import HomepageBlockLayout from '../HomepageBlockLayout'
import ScrollableContentBody from '../ScrollableContentBody'
import useLayoutsTracking from '../hooks/useLayoutsTracking'

type Props = {
  elements: Array<LayoutElementModel>
  name: string
  position: number
  homepageSessionId: string
  id: string
  title: string
  subtitle: string | null
}

type RowElementProps = {
  name: string
  element: LayoutElementModel
  homepageSessionId: string
  position: number
  accessibilityLabel: string | null
}

const RowElement = ({
  name,
  element,
  homepageSessionId,
  position,
  accessibilityLabel,
}: RowElementProps) => {
  const breakpoints = useBreakpoint()
  const { handleItemClick, handleItemView } = useLayoutsTracking({ name, homepageSessionId })

  return (
    <InView
      className={classNames('single-row-element', `row-element-${position + 1}`, {
        'row-element-mobile': !breakpoints.desktops,
      })}
      onChange={handleItemView(element, position)}
    >
      <Card>
        <Cell
          styling={Cell.Styling.Tight}
          clickable
          url={element.cta.url}
          type={Cell.Type.Navigating}
          prefix={
            <Image
              scaling={Image.Scaling.Cover}
              ratio={Image.Ratio.Square}
              src={element.photo.url}
              size={breakpoints.desktops ? Image.Size.XLarge : Image.Size.Large}
            />
          }
          body={
            <div className="homepage-layouts-text-truncation">
              <Text
                width={Text.Width.Parent}
                type={Text.Type.Subtitle}
                text={element.title}
                alignment={Text.Alignment.Left}
                theme="amplified"
                as="p"
                bold
              />
            </div>
          }
          onClick={handleItemClick(element, position)}
          testId="homepage-layouts-horizontal-rows"
          aria={{
            'aria-label': accessibilityLabel || '',
          }}
        />
      </Card>
    </InView>
  )
}

const HorizontalRowsBlock = ({
  elements,
  name,
  position,
  homepageSessionId,
  id,
  title,
  subtitle,
}: Props) => {
  const breakpoints = useBreakpoint()

  const renderRows = () =>
    elements.map((element, index) => (
      <RowElement
        element={element}
        name={name}
        homepageSessionId={homepageSessionId}
        position={index}
        key={`horizontal-rows-element-${position}-${element.title}`}
        accessibilityLabel={element.cta.accessibilityLabel}
      />
    ))

  return (
    <HomepageBlockLayout
      name={name}
      position={position}
      homepageSessionId={homepageSessionId}
      id={id}
      headerProps={{ title, subtitle }}
      body={
        <ScrollableContentBody
          desktopView={breakpoints.desktops}
          name={name}
          desktopClassName="horizontal-rows-block-container horizontal-rows-desktop"
          mobileClassName="horizontal-rows-block-container"
        >
          {renderRows()}
        </ScrollableContentBody>
      }
    />
  )
}

export default HorizontalRowsBlock
