import { useRef } from 'react'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { LayoutElementModel } from 'types/models/homepage-layouts'
import {
  userClickHomepageElement,
  userViewHomepageElement,
} from '_libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'

interface Props {
  name: string
  homepageSessionId: string
}

const useLayoutsTracking = ({ name, homepageSessionId }: Props) => {
  const { track } = useTracking()

  const seenThumbnails = useRef<Array<string>>([])

  const handleItemView = (thumbnail: LayoutElementModel, index: number) => (inView: boolean) => {
    if (!inView) return

    const seenThumbnail = thumbnail.id || thumbnail.title
    if (seenThumbnails.current.includes(seenThumbnail)) return

    seenThumbnails.current.push(seenThumbnail)

    track(
      userViewHomepageElement({
        blockName: name,
        position: index + 1,
        contentSource: thumbnail.contentSource,
        contentSourceLabel: thumbnail.title,
        contentSourceId: thumbnail.id,
        homepageSessionId,
        screen: Screen.NewsFeed,
      }),
    )
  }

  const handleItemClick = (thumbnail: LayoutElementModel, index: number) => () => {
    track(
      userClickHomepageElement({
        blockName: name,
        position: index + 1,
        contentSource: thumbnail.contentSource,
        contentSourceLabel: thumbnail.title,
        contentSourceId: thumbnail.id,
        homepageSessionId,
        screen: Screen.NewsFeed,
      }),
    )
  }

  return {
    handleItemClick,
    handleItemView,
  }
}

export default useLayoutsTracking
