'use client'

import classNames from 'classnames'
import { Cell, Text, Image } from '@vinted/web-ui'
import { InView } from 'react-intersection-observer'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { LayoutElementModel } from 'types/models/homepage-layouts'
import { ThumbnailsBlockStyle } from 'constants/home'

import HomepageBlockLayout from '../HomepageBlockLayout'
import ScrollableContentBody from '../ScrollableContentBody'
import useLayoutsTracking from '../hooks/useLayoutsTracking'

type Props = {
  elements: Array<LayoutElementModel>
  title: string
  name: string
  position: number
  homepageSessionId: string
  id: string
  style: ThumbnailsBlockStyle
  subtitle: string | null
}

type ThumbnailProps = {
  imageSrc: string
  title: string
  ctaUrl: string
  style: ThumbnailsBlockStyle
  accessibilityLabel: string | null
  handleItemClick(): void
}

const Thumbnail = ({
  imageSrc,
  title,
  ctaUrl,
  handleItemClick,
  style,
  accessibilityLabel,
}: ThumbnailProps) => {
  const breakpoints = useBreakpoint()

  const bigImageStyle = style === ThumbnailsBlockStyle.BigImage
  const smallRoundImageStyle = style === ThumbnailsBlockStyle.SmallRoundImage

  const desktopView = bigImageStyle ? breakpoints.tabletsUp : breakpoints.wide
  const desktopImageSize = smallRoundImageStyle ? Image.Size.X2Large : undefined

  const getMobileImageSize = () => {
    if (bigImageStyle) return Image.Size.X4Large
    if (smallRoundImageStyle) return Image.Size.X2Large

    return Image.Size.X3Large
  }

  return (
    <article className="single-thumbnail-container">
      <Cell
        styling={Cell.Styling.Tight}
        clickable
        url={ctaUrl}
        onClick={handleItemClick}
        testId="homepage-layouts-thumbnail"
        aria={{
          'aria-label': accessibilityLabel || '',
        }}
        body={
          <div className="single-thumbnail-container-body">
            <div
              className={classNames('thumbnail-image', {
                'thumbnail-image--small-round': smallRoundImageStyle,
              })}
            >
              <Image
                scaling={Image.Scaling.Cover}
                ratio={Image.Ratio.Square}
                src={imageSrc}
                size={desktopView ? desktopImageSize : getMobileImageSize()}
                styling={smallRoundImageStyle ? Image.Styling.Circle : Image.Styling.Rounded}
              />
            </div>
            <Cell styling={smallRoundImageStyle ? Cell.Styling.Narrow : Cell.Styling.Tight}>
              <div
                className={classNames(
                  'thumbnail-title-wrapper',
                  'homepage-layouts-text-truncation',
                  {
                    'big-thumbnail-title': bigImageStyle,
                    'small-round-thumbnail-title': smallRoundImageStyle,
                    'u-ui-padding-top-small': !smallRoundImageStyle,
                  },
                )}
              >
                <Text
                  width={Text.Width.Parent}
                  type={Text.Type.Title}
                  text={title}
                  alignment={smallRoundImageStyle ? Text.Alignment.Center : Text.Alignment.Left}
                  as="h3"
                />
              </div>
            </Cell>
          </div>
        }
      />
    </article>
  )
}

const ThumbnailsBlock = ({
  elements,
  title,
  name,
  subtitle,
  position,
  homepageSessionId,
  id,
  style,
}: Props) => {
  const { handleItemClick, handleItemView } = useLayoutsTracking({ name, homepageSessionId })
  const breakpoints = useBreakpoint()

  const bigImageStyle = style === ThumbnailsBlockStyle.BigImage
  const desktopClassName = bigImageStyle
    ? 'thumbnails-layout-container thumbnails-big-image'
    : 'thumbnails-layout-container thumbnails-small-image'
  const desktopView = bigImageStyle ? breakpoints.tabletsUp : breakpoints.wide

  const renderThumbnails = () =>
    elements.map((thumbnail, index) => (
      <InView
        key={`thumbnail-${index}-${thumbnail.title}`}
        onChange={handleItemView(thumbnail, index)}
        className="u-fill-width"
      >
        <Thumbnail
          title={thumbnail.title}
          imageSrc={thumbnail.photo.url}
          ctaUrl={thumbnail.cta.url}
          handleItemClick={handleItemClick(thumbnail, index)}
          style={style}
          accessibilityLabel={thumbnail.cta.accessibilityLabel}
        />
      </InView>
    ))

  return (
    <HomepageBlockLayout
      name={name}
      position={position}
      homepageSessionId={homepageSessionId}
      id={id}
      headerProps={{ title, subtitle }}
      body={
        <ScrollableContentBody
          desktopView={desktopView}
          name={name}
          desktopClassName={desktopClassName}
          mobileClassName="thumbnails-layout-mobile-container"
        >
          {renderThumbnails()}
        </ScrollableContentBody>
      }
    />
  )
}

export default ThumbnailsBlock
